.game-list {
  width: 100%;
  padding: 15px 0px;
  border-radius: 20px;
}

.category-logo {
  border: 1px solid red;
  border-radius: 50%;
  background-size: 60% !important;
  background-position: center !important;
}

.topic-item {
  padding: 5px 10px;
  color: #1f6d96;
}

/* question cards swipe animation starts */

#board {
  width: 100%;
  height: 100%;
  position: relative;
  /* overflow: hidden; */
}

.question-card {
  padding: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 2%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  transform: translateX(-50%) translateY(-50%) scale(0.95);
}

.swipeLeft {
  animation: swipeLeft 0.5s ease-in-out;
}
.swipeRight {
  animation: swipeRight 0.5s ease-in-out;
}

@keyframes swipeLeft {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(0);
  }
  100% {
    transform: translateX(-150%) translateY(-40%) rotate(-30deg);
  }
}

@keyframes swipeRight {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(0);
  }
  100% {
    transform: translateX(50%) translateY(-40%) rotate(30deg);
  }
}

/* question cards swipe animation ends */

/* spinner animation starts */

.spinner {
  --size: 32px;

  width: var(--size);
  height: var(--size);

  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
}

.inner.one {
  left: 0;
  top: 0;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #2cfffe;
}

.inner.two {
  right: 0;
  top: 0;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #fcc10f;
}

.inner.three {
  right: 0;
  bottom: 0;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #ec1b60;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

/* spinner animation ends */

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
