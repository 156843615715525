*,
*:after,
*:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
* {
  -webkit-tap-highlight-color: transparent;
}
::-moz-selection {
  background: #ed7133;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #ed7133;
  color: #fff;
  text-shadow: none;
}
:focus {
  outline: 0 none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
html,
button,
input,
select,
textarea {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fff;
}
.content-wrapper {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
}
img {
  border: 0 none;
  max-width: 100%;
  vertical-align: top;
  height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
}
a {
  color: #000;
  text-decoration: none;
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  transition: all linear 0.2s;
  cursor: pointer;
}
a:hover {
  color: #ed7133;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
blockquote {
  margin: 1em 40px;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
pre {
  margin: 1em 0;
}
p {
  margin: 0 0 15px;
}
p:last-of-type {
  margin-bottom: 0;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  _font-family: "courier new", monospace;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: none;
}
q:before,
q:after {
  content: "";
  content: none;
}
small {
  font-size: 75%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
dl,
menu {
  margin: 0;
  padding: 0;
}
dd {
  margin: 0 0 0 40px;
}
nav ul,
nav ol {
  list-style: none;
}
iframe {
  border: 0;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
  white-space: normal;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}
button,
input {
  line-height: normal;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  transition: all linear 0.2s;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
table th,
table td {
  padding: 10px 20px 7px;
  line-height: 1.2;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
table th {
  background-color: #231f20;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.5px;
}
table td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
}
address {
  font-style: normal;
  font-size: 14px;
}
hr {
  border: 0 none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 30px 0;
  display: block;
  width: 100%;
}
ul,
ol {
  margin: 20px 0;
  padding-left: 15px;
}
ul ul,
ol ol {
  margin: 10px 0;
}
ul ul li {
  list-style: circle outside;
}
ol ol li {
  list-style: lower-roman outside;
}
body {
  font-family: "Source Sans Pro", sans-serif;
  color: #000;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
}
.container {
  width: 100%;
  position: relative;
  padding: 15px;
}
.formControl {
  display: block;
  padding: 0 30px 0 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  color: #ed7133;
  width: 100%;
  height: 40px;
  border: 0 none;
  border-bottom: 1px solid #ed7133;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.formControl:-moz-placeholder {
  color: #18455d;
  opacity: 1;
}
.formControl::-moz-placeholder {
  color: #18455d;
  opacity: 1;
}
.formControl:-ms-input-placeholder {
  color: #18455d;
  opacity: 1;
}
.formControl::-webkit-input-placeholder {
  color: #18455d;
  opacity: 1;
}
.checkbox label {
  display: block;
  font-size: 12px;
  line-height: 1.7;
  padding-left: 25px;
  color: #18455d;
  position: relative;
}
.checkbox label a {
  font-weight: 700;
  color: #ed7133;
}
.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}
.checkbox label::before {
  background: url(assets/img/icon-checkbox.svg) no-repeat center top;
  background-size: 100% auto;
  opacity: 0;
}
.checkbox label::after {
  border: 1px solid #18455d;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.checkbox input {
  display: none;
}
.checkbox input:checked + label::before {
  opacity: 1;
}
.checkbox input:checked + label::after {
  opacity: 0;
}
.btn {
  border: 0 none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
  background: #ed7133;
  background: -moz-linear-gradient(left, #ed7133 0%, #ff9a68 100%);
  background: -webkit-linear-gradient(left, #ed7133 0%, #ff9a68 100%);
  background: linear-gradient(to right, #ed7133 0%, #ff9a68 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ed7133', endColorstr='#ff9a68',GradientType=1 );
  font-size: 22px;
  line-height: 0.8;
  font-weight: 700;
  color: #fff !important;
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  text-align: center;
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.btn:hover {
  color: #fff;
}
.center {
  text-align: center;
}
.splash-screen {
  height: 100%;
  background: #ffe7da;
  background: -moz-linear-gradient(top, #ffe7da 0%, #caedff 100%);
  background: -webkit-linear-gradient(top, #ffe7da 0%, #caedff 100%);
  background: linear-gradient(to bottom, #ffe7da 0%, #caedff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffe7da', endColorstr='#caedff',GradientType=0 );
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.splash-screen img {
  display: block;
  width: 100%;
  max-width: 350px;
}
.header {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* width: 100vw; */
  z-index: 100;
  display: flex;
}
.header .logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .header.large {
  min-height: 300px;
  background-image: url(assets/img/header-bg-large.png);
}
.header.large .logo {
  height: 220px;
}
.header.medium {
  min-height: 170px;
  background-image: url(assets/img/header-bg-medium.png);
}
.header.medium .logo {
  height: 100px;
  align-items: flex-end;
} */
.header.small {
  min-height: 130px;
  background-image: url(assets/img/header-bg-small.png);
}
.header.small .logo {
  height: 80px;
  align-items: flex-end;
}
.header.menu-header {
  align-items: center;
  padding: 25px 0 55px;
}
.header .nav-button,
.header .notification {
  width: 50px;
  height: 50px;
  font-size: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px auto;
}
.header .nav-button.empty,
.header .notification.empty {
  background-image: none !important;
}
.header .nav-button {
  background-image: url(assets/img/icon-nav.svg);
}
.header .notification {
  background-image: url(assets/img/icon-notification.svg);
  position: relative;
}

.header .notification.isAnimating {
  animation: ringing 800ms;
}

@keyframes ringing {
  0%,
  100% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(10deg);
  }
  40%,
  80% {
    transform: rotate(-6deg);
  }
  60% {
    transform: rotate(6deg);
  }
}

.header .notification.notif-badge::after {
  background-color: #00bf92;
  border: 2px solid;
  border-radius: 11px;
  content: attr(data-count);
  color: #fff;
  display: block;
  font-size: 10px;
  font-family: helvetica;
  height: 16px;
  line-height: 3px;
  min-width: 20px;
  padding: 4px;
  position: absolute;
  right: 2px;
  text-align: center;
  top: 10px;
  transition: 100ms ease-in-out all;
}
.header .page-heading {
  width: calc(100% - 100px);
  min-height: 1px;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
.header .coins {
  border: 1px solid #ed7133;
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 3px 5px 3px 30px;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  color: #ed7133;
  position: relative;
  margin-right: 15px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
}
.header .coins::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 5px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background: url(assets/img/icon-coin.svg) no-repeat left top;
  background-size: 100% auto;
}
.bg-gradient {
  background: #ffe7da;
  background: -moz-linear-gradient(top, #ffe7da 0%, #caedff 100%);
  background: -webkit-linear-gradient(top, #ffe7da 0%, #caedff 100%);
  background: linear-gradient(to bottom, #ffe7da 0%, #caedff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffe7da', endColorstr='#caedff',GradientType=0 );
}
.bg-gradient .header {
  padding: 30px 0 15px;
  align-items: center;
}
.bg-gradient .container {
  padding-top: 100px;
}
.icon-circle-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  z-index: 5;
}
.icon-login {
  background-image: url(assets/img/icon-circle-login.png);
}
.icon-forgot-password {
  background-image: url(assets/img/icon-circle-forgot-password.png);
}
.icon-reset-password {
  background-image: url(assets/img/icon-circle-reset-password.png);
}
.icon-success {
  background-image: url(assets/img/icon-circle-success.png);
}
.form-section {
  padding-top: 310px;
}
.form-section .inner-wrap {
  position: relative;
}
.form-section .inner-wrap .icon-circle-block {
  top: -135px;
}
.form-section .info {
  color: #18455d;
  margin-bottom: 20px;
}
.form-section.signup {
  padding-top: 190px;
}
.form-section.lmi {
  padding-top: 160px;
}
.form-section.lmi .inner-wrap {
  height: calc(100% - 260px);
}
.form-section.lmi .btn {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
  width: calc(100% - 30px);
}
.form-section.indeed {
  padding-top: 160px;
}
.form-section.indeed .inner-wrap {
  height: calc(100% - 240px);
}
.form-section.indeed .btn {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
  width: calc(100% - 30px);
}
.form-section.edit-profile {
  padding-top: 150px;
}
.form-section .user-img {
  margin-bottom: 60px;
  position: relative;
}
.form-section .user-img input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.form-section .user-img img {
  display: block;
  width: 110px;
  height: 120px;
  margin: 0 auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
.form-section .user-img .icon-edit {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 25px;
  height: 25px;
  margin: 0 auto;
  background: url(assets/img/icon-edit.svg) no-repeat center center #ed7133;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #ed7233;
  -webkit-transform: translate(0, 50%);
  -moz-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.field {
  position: relative;
  margin-bottom: 30px;
}
.field.mb-75 {
  margin-bottom: 75px;
}
.field label {
  position: absolute;
  top: 0;
  left: 0;
  color: #18455d;
  font-size: 12px;
  line-height: 40px;
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  transition: all linear 0.2s;
  cursor: text;
  text-transform: uppercase;
}
.field .field-box span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 100% auto;
}
.field .field-box span.icon-email {
  background-image: url(assets/img/icon-email.svg);
}
.field .field-box span.icon-password {
  background-image: url(assets/img/icon-password.svg);
}
.field .field-box span.icon-phone {
  background-image: url(assets/img/icon-phone.svg);
}
.field .field-box span.icon-user {
  background-image: url(assets/img/icon-user.svg);
  background-size: 15px auto;
}
.field .field-box span.icon-location {
  background-image: url(assets/img/icon-location.svg);
  background-size: 15px auto;
}
.field .field-box span.active {
  opacity: 0.6;
}
.field .field-box.active ~ label {
  -webkit-transform: translate(0, -20px);
  -moz-transform: translate(0, -20px);
  transform: translate(0, -20px);
}
.field .forgot-link {
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 12px;
  line-height: 1.2;
  margin-top: 5px;
}
.field .forgot-link a {
  color: #18455d;
}
.other-link {
  text-align: center;
  padding-top: 10px;
  color: #18455d;
  font-size: 14px;
  line-height: 1;
}
.other-link a {
  color: #ed7133;
  text-transform: uppercase;
  font-weight: 700;
}
.other-link a.link-cancel {
  color: #18455d;
  text-transform: none;
  font-weight: 400;
}
.signup .checkbox {
  margin-top: -20px;
  margin-bottom: 50px;
}
.field-forgot-password {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  font-size: 18px;
}
.field-forgot-password p {
  margin-bottom: 20px;
  max-width: 90%;
}
.forgot-password {
  display: flex;
}
.forgot-password .formControl {
  width: 40px;
  margin: 0 5px;
  text-align: center;
  padding: 0;
  font-size: 20px;
}
.success-message-password {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 150px;
  padding-top: 20px;
}
.success-message-password p {
  font-size: 18px;
  max-width: 80%;
}
.page-block {
  padding: 160px 15px 15px;
  height: calc(100% - 60px);
  position: relative;
}
.heading-blue {
  font-size: 22px;
  font-weight: 600;
}
.home-to-do-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.home-to-do-list li a {
  color: #ed7133;
}
.home-to-do-list li a .icon {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.home-to-do-list li a .icon.icon-play-game {
  background-image: url(assets/img/icon-circle-play-game-white.svg);
}
.home-to-do-list li a .icon.icon-resources {
  background-image: url(assets/img/icon-circle-resources-white.svg);
}
.home-to-do-list li a .text {
  display: block;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
}
.home-to-do-list li a:focus .icon {
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.75);
}
.home-to-do-list li a:focus .icon.icon-play-game {
  background-image: url(assets/img/icon-circle-play-game-orange.svg);
}
.home-to-do-list li a:focus .icon.icon-resources {
  background-image: url(assets/img/icon-circle-resources-orange.svg);
}
.chosen-container {
  position: relative;
  display: block;
  font-size: 12px;
  width: 100% !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.chosen-container .chosen-drop {
  position: absolute;
  top: 100%;
  z-index: 1010;
  width: calc(100% - 40px);
  margin: 0 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
}
.chosen-container.chosen-with-drop .chosen-drop {
  clip: auto;
  -webkit-clip-path: none;
  clip-path: none;
}
.chosen-container a {
  cursor: pointer;
}
.chosen-container .chosen-single .group-name,
.chosen-container .search-choice .group-name {
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  color: #999;
}
.chosen-container .chosen-single .group-name:after,
.chosen-container .search-choice .group-name:after {
  content: ":";
  padding-left: 2px;
  vertical-align: top;
}
.chosen-container-single .chosen-single {
  position: relative;
  display: block;
  overflow: hidden;
  padding-left: 20px;
  height: 45px;
  line-height: 45px;
  color: #18455d;
  font-weight: 400;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  border-radius: 45px;
  background-color: #f3f3f3;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
}
.chosen-container-single .chosen-single span {
  display: block;
  overflow: hidden;
  margin-right: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px;
}
.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  font-size: 1px;
}
.chosen-container-single .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single .chosen-single div {
  position: absolute;
  top: 18px;
  right: 15px;
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
}
.chosen-container-single .chosen-single div b {
  display: block;
  width: 100%;
  height: 100%;
}
.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 4px;
  white-space: nowrap;
}
.chosen-container-single .chosen-search input[type="text"] {
  margin: 1px 0;
  padding: 4px 20px 4px 5px;
  width: 100%;
  height: auto;
  outline: 0;
  border: 1px solid #aaa;
  font-size: 1em;
  font-family: sans-serif;
  line-height: normal;
  border-radius: 0;
}
.chosen-container-single .chosen-drop {
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(100% 100%);
  clip-path: inset(100% 100%);
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(100% 100%);
  clip-path: inset(100% 100%);
}
.chosen-container .chosen-results {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
}
.chosen-container .chosen-results li {
  display: none;
  padding: 8px 10px;
  border-bottom: 1px solid #f4f4f4;
  letter-spacing: 1px;
  word-wrap: break-word;
  -webkit-touch-callout: none;
}
.chosen-container .chosen-results li:last-child {
  border-bottom: 0 none;
}
.chosen-container .chosen-results li.active-result {
  display: list-item;
  cursor: pointer;
}
.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: #ccc;
  cursor: default;
}
.chosen-container .chosen-results li.highlighted {
  background-color: #ed7133;
  color: #fff;
}
.chosen-container .chosen-results li.no-results {
  color: #777;
  display: list-item;
  background: #f4f4f4;
}
.chosen-container .chosen-results li.group-result {
  display: list-item;
  font-weight: 700;
  cursor: default;
}
.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}
.chosen-container .chosen-results li em {
  font-style: normal;
  text-decoration: underline;
}
.chosen-container-multi .chosen-choices {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 5px;
  width: 100%;
  height: auto;
  border: 1px solid #aaa;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1%, #eee),
    color-stop(15%, #fff)
  );
  background-image: linear-gradient(#eee 1%, #fff 15%);
  cursor: text;
}
.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}
.chosen-container-multi .chosen-choices li.search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  margin: 1px 0;
  padding: 0;
  height: 25px;
  outline: 0;
  border: 0 !important;
  background: 0 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #999;
  font-size: 100%;
  font-family: sans-serif;
  line-height: normal;
  border-radius: 0;
  width: 25px;
}
.chosen-container-multi .chosen-choices li.search-choice {
  position: relative;
  margin: 3px 5px 3px 0;
  padding: 3px 20px 3px 5px;
  border: 1px solid #aaa;
  max-width: 100%;
  border-radius: 3px;
  background-color: #eee;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(20%, #f4f4f4),
    color-stop(50%, #f0f0f0),
    color-stop(52%, #e8e8e8),
    to(#eee)
  );
  background-image: linear-gradient(
    #f4f4f4 20%,
    #f0f0f0 50%,
    #e8e8e8 52%,
    #eee 100%
  );
  background-size: 100% 19px;
  background-repeat: repeat-x;
  background-clip: padding-box;
  -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  color: #333;
  line-height: 13px;
  cursor: default;
}
.chosen-container-multi .chosen-choices li.search-choice span {
  word-wrap: break-word;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  position: absolute;
  top: 4px;
  right: 3px;
  display: block;
  width: 12px;
  height: 12px;
  font-size: 1px;
}
.chosen-container-multi
  .chosen-choices
  li.search-choice
  .search-choice-close:hover {
  background-position: -42px -10px;
}
.chosen-container-multi .chosen-choices li.search-choice-disabled {
  padding-right: 5px;
  border: 1px solid #ccc;
  background-color: #e4e4e4;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(20%, #f4f4f4),
    color-stop(50%, #f0f0f0),
    color-stop(52%, #e8e8e8),
    to(#eee)
  );
  background-image: linear-gradient(
    #f4f4f4 20%,
    #f0f0f0 50%,
    #e8e8e8 52%,
    #eee 100%
  );
  color: #666;
}
.chosen-container-multi .chosen-choices li.search-choice-focus {
  background: #d4d4d4;
}
.chosen-container-multi
  .chosen-choices
  li.search-choice-focus
  .search-choice-close {
  background-position: -42px -10px;
}
.chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0;
}
.chosen-container-multi .chosen-drop .result-selected {
  display: list-item;
  color: #ccc;
  cursor: default;
}
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}
.game-home {
  padding-top: 65px;
}
.game-home .img {
  margin-bottom: 20px;
}
.game-home .img img {
  display: block;
  width: 100vw;
  height: 64vw;
}
.game-home h3 {
  text-align: center;
  padding: 0 15px;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 20px;
}
.game-home .game-dropdown {
  padding: 0 15px;
  margin-bottom: 15px;
}
.game-home .game-dropdown select {
  border: 0 none;
  background-color: rgba(216, 216, 216, 0.3);
}
.game-block {
  padding: 15px;
}
.game-block h4 {
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  color: #18455d;
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 25px;
}
.game-block ul {
  margin: 0 0 35px;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  justify-content: space-around;
  flex-wrap: wrap;
}
.game-block ul li {
  justify-self: center;
}
.game-block ul li .day-box {
  position: relative;
}
.game-block ul li .day-box .gradient-box {
  height: 85px;
  width: 85px;
  background: #ffe7da;
  background: -moz-linear-gradient(top, #ffe7da 0%, #caedff 100%);
  background: -webkit-linear-gradient(top, #ffe7da 0%, #caedff 100%);
  background: linear-gradient(to bottom, #ffe7da 0%, #caedff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffe7da', endColorstr='#caedff',GradientType=0 );
  text-align: center;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #18455d;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.game-block ul li .day-box .day-count {
  display: block;
  width: 100%;
  line-height: 1;
  font-size: 33px;
  font-weight: 700;
}
.game-block ul li .day-box .day-count em {
  font-size: 18px;
  font-weight: 600;
  display: block;
  font-style: normal;
}
.game-block ul li.locked .day-box .gradient-box {
  opacity: 0.55;
}
.game-block ul li.locked .day-box::after {
  position: absolute;
  content: "";
  bottom: 0;
  right: -10px;
  width: 20px;
  height: 27px;
  background: url(assets/img/icon-lock.svg) no-repeat left top;
}
.game-block ul li:nth-child(3n-1) .day-box {
  margin: 0 auto;
}
.game-block ul li:nth-child(3n) .day-box {
  float: right;
}
.game-block ul li:nth-child(3n)::after {
  content: "";
  clear: both;
}
.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  transition: all linear 0.2s;
}

.popup-reset {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  transition: all linear 0.2s;
}

.popup .popup-box {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 92%;
  margin: 0 auto;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  max-height: 450px;
  overflow: auto;
  padding: 35px 10px;
}

.popup-reset .popup-box {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 92%;
  margin: 0 auto;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  max-height: 450px;
  overflow: auto;
  padding: 35px 10px;
}

.popup .popup-box .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: url(assets/img/icon-close.svg) no-repeat center center;
  background-size: 15px auto;
}

.popup-reset .popup-box .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: url(assets/img/icon-close.svg) no-repeat center center;
  background-size: 15px auto;
}

.popup .popup-box h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #18455d;
  position: relative;
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.popupreset .popup-box h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #18455d;
  position: relative;
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.popup .popup-box h5::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  width: 90px;
  height: 1px;
  margin: 0 auto;
  background-color: #18455d;
}

.popup-reset .popup-box h5::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  width: 90px;
  height: 1px;
  margin: 0 auto;
  background-color: #18455d;
}

.popup .popup-box p:last-of-type {
  margin-bottom: 15px;
}

.popup-reset .popup-box p:last-of-type {
  margin-bottom: 15px;
}

.popup.active {
  opacity: 1;
  visibility: visible;
}

.popup-reset.active {
  opacity: 1;
  visibility: visible;
}
.listing-dotted {
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
}
.listing-dotted li {
  list-style: none;
  position: relative;
  padding-left: 10px;
  line-height: 1.3;
  margin-bottom: 5px;
}
.listing-dotted li::after {
  position: absolute;
  content: "";
  top: 8px;
  left: 0;
  width: 5px;
  height: 5px;
  background-color: #ed7133;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.listing-dotted li ul {
  margin: 10px 0 0;
  padding-left: 0;
}
.confirmation-block {
  padding: 230px 15px 15px;
}
.confirmation-block .confirmation-box {
  position: relative;
  background: url(assets/img/bg-game-confirmation.svg) no-repeat center top;
  background-size: 100% auto;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 90px;
}
.confirmation-block .confirmation-box .icon-circle-game {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background: url(assets/img/icon-cirlce-game.svg) no-repeat center center #fff;
  background-size: 100% auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.confirmation-block .confirmation-box p {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
  color: #18455d;
}
.activity-success-block {
  padding: 160px 20px 20px;
}
.activity-success-block .icon-check {
  display: block;
  background: url(assets/img/icon-check.svg) no-repeat center center;
  background-size: 100% auto;
  width: 85px;
  height: 85px;
  margin: 0 auto 20px;
}
.activity-success-block h4 {
  font-size: 18px;
  color: #ed7133;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.activity-success-block h5 {
  font-size: 22px;
  line-height: 1;
  text-align: center;
  padding-bottom: 5px;
  color: #18455d;
  position: relative;
  margin-bottom: 20px;
}
.activity-success-block h5::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  width: 90px;
  height: 1px;
  margin: 0 auto;
  background-color: #3d6277;
}
.activity-success-block ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.activity-success-block ul li {
  padding-left: 20px;
  color: #18455d;
  line-height: 1.4;
  background-repeat: no-repeat;
  background-position: left 5px;
  background-size: 15px auto;
  margin-bottom: 25px;
}
.activity-success-block ul li.incomplete {
  background-image: url(assets/img/icon-incomplete.svg);
}
.activity-success-block ul li.complete {
  background-image: url(assets/img/icon-complete.svg);
}
.congratulations-page {
  padding: 170px 15px 15px;
  text-align: center;
}
.congratulations-page .icon-tick {
  display: block;
  width: 150px;
  height: 145px;
  background: url(assets/img/icon-check-2.svg) no-repeat left top;
  background-size: 100% auto;
  margin: 0 auto 20px;
}
.congratulations-page h2 {
  font-size: 28px;
  font-weight: 700;
  color: #ed7133;
  margin-bottom: 10px;
}
.congratulations-page p {
  font-size: 18px;
}
.congratulations-page p.info {
  margin-top: 80px;
}
.resources-page {
  padding: 175px 15px 15px;
}
.resources-page .block-resource {
  margin-bottom: 55px;
}
.resources-page .block-resource a {
  display: block;
  background: url(assets/img/bg-resource.svg) no-repeat center top;
  background-size: 100% auto;
  position: relative;
  text-align: center;
  height: 117px;
  color: #ed7133;
  padding-top: 65px;
}
.resources-page .block-resource a .icon-circle {
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.resources-page .block-resource a .icon-circle.icon-circle-lmi {
  background-image: url(assets/img/icon-circle-lmi.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-search {
  background-image: url(assets/img/icon-circle-search.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-career-development {
  background-image: url(assets/img/icon-circle-career-development.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-leadership {
  background-image: url(assets/img/icon-circle-leadership.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-management {
  background-image: url(assets/img/icon-circle-management.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-creativity {
  background-image: url(assets/img/icon-circle-creativity.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-research {
  background-image: url(assets/img/icon-circle-research.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-analysis {
  background-image: url(assets/img/icon-circle-analysis.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-goals {
  background-image: url(assets/img/icon-circle-goals.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-player {
  background-image: url(assets/img/icon-circle-player.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-learning {
  background-image: url(assets/img/icon-circle-learning.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-leadership-2 {
  background-image: url(assets/img/icon-circle-leadership-2.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-professional {
  background-image: url(assets/img/icon-circle-professional.svg);
}
.resources-page .block-resource a .icon-circle.icon-circle-mentor {
  background-image: url(assets/img/icon-circle-mentor.svg);
}
.resources-page .block-resource a .text {
  display: block;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}
.resources-page.sub-category-page .block-resource a .text {
  text-transform: none;
}
.job-search-page {
  padding: 160px 40px 15px;
}
.job-search-page ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.job-search-page ul li {
  width: 120px;
  margin-bottom: 25px;
}
.job-search-page ul li a,
.job-search-page ul li span {
  display: block;
  color: #18455d;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
}
.job-search-page ul li .icon-circle {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
  margin-bottom: 10px;
}
.job-search-page ul li .icon-circle.icon-circle-career {
  background-image: url(assets/img/icon-circle-career.svg);
}
.job-search-page ul li .icon-circle.icon-circle-networking {
  background-image: url(assets/img/icon-circle-networking.svg);
}
.job-search-page ul li .icon-circle.icon-circle-career-letter {
  background-image: url(assets/img/icon-circle-career-letter.jpg);
}
.job-search-page ul li .icon-circle.icon-circle-resume {
  background-image: url(assets/img/icon-circle-resume.svg);
}
.job-search-page ul li .icon-circle.icon-circle-applications {
  background-image: url(assets/img/icon-circle-applications.svg);
}
.job-search-page ul li .icon-circle.icon-circle-interviews {
  background-image: url(assets/img/icon-circle-interviews.svg);
}
.job-search-page ul li .icon-circle.icon-circle-job-offer {
  background-image: url(assets/img/icon-circle-job-offer.svg);
}
.job-search-page ul li .icon-circle.icon-circle-starting-job {
  background-image: url(assets/img/icon-circle-starting-job.svg);
}
.job-search-page ul li .icon-circle.icon-circle-job-skills {
  background-image: url(assets/img/icon-circle-job-skills.svg);
}
.job-search-page ul li .icon-circle.icon-circle-competencies {
  background-image: url(assets/img/icon-circle-competencies.svg);
}
.job-search-page ul li .icon-circle.icon-circle-promoted {
  background-image: url(assets/img/icon-circle-promoted.svg);
}
.notification-page {
  padding: 160px 15px 15px;
  position: relative;
  height: 100%;
}
.notification-page ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.notification-page ul li {
  position: relative;
  padding-left: 30px;
  background: url(assets/img/icon-notification-ring.svg) no-repeat left 3px;
  background-size: 20px auto;
  margin-bottom: 20px;
}
.notification-page .bottom-block {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100px;
  background-color: #f3f3f3;
}
.notification-page .btn-clear {
  display: block;
  width: 65px;
  margin: 0 auto 25px;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  font-size: 14px;
  text-transform: uppercase;
}
.notification-page .btn-clear span {
  display: block;
}
.notification-page .btn-clear .icon-clear {
  height: 65px;
  background: url(assets/img/icon-clear.svg) no-repeat left top;
  background-size: 100% auto;
  margin-bottom: 10px;
}
.notification-page .bottom-block .box {
  padding: 15px;
  font-size: 14px;
}
.notification-page .bottom-block .box a {
  text-transform: uppercase;
  font-weight: 700;
  color: #18455d;
}
.announcement-page {
  padding: 160px 15px 15px;
  text-align: center;
}
.announcement-page img {
  display: block;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.announcement-page h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}
.announcement-page p {
  font-size: 14px;
}
.help-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.help-page p {
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
}
.help-page .btn {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 80px;
  width: calc(100% - 30px);
}
.faq-page {
  padding: 160px 15px 15px;
}
.faq-page ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.faq-page ul li {
  margin-bottom: 20px;
}
.faq-page .acc-heading {
  position: relative;
  padding: 0 35px 0 25px;
  font-size: 14px;
  font-weight: 600;
}
.faq-page .acc-heading::before {
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  background-position: left top;
  width: 18px;
  height: 18px;
  top: 0;
}
.faq-page .acc-heading::before {
  left: 0;
  background-image: url(assets/img/icon-question.svg);
}
/* .faq-page .acc-heading::after {
  right: 0;
  background-image: url(assets/img/icon-plus-minus.svg);
}
.faq-page .acc-heading.open::after {
  background-position: right top;
} */
.faq-page .acc-heading .acc-icon {
  background-image: url(assets/img/add.png);
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  width: 13px;
  height: 13px;
  top: 5px;
  right: 0;
}
.faq-page .acc-heading.open .acc-icon {
  background-image: url(assets/img/minus.png);
}
.faq-page .acc-box {
  display: none;
  padding: 10px 35px 0 25px;
  font-size: 14px;
}

/* No Need, to be deleted later */
/* .calendar-week {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.calendar-week .box-day span {
  display: block;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
}
.calendar-week .box-day span.day {
  font-size: 14px;
  margin-bottom: 5px;
}
.calendar-week .box-day span.date {
  padding-top: 4px;
  width: 25px;
  height: 25px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-weight: 600;
}
.calendar-week .box-day.incomplete span.date {
  background-color: #ed7133;
  color: #fff;
}
.calendar-week .box-day.complete span.date {
  background-color: #00b908;
  color: #fff;
} */
.white-box {
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  padding: 25px 15px;
}
.white-box h3 {
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: #18455d;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 30px;
}
.white-box h3::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: 0 auto;
  height: 1px;
  width: 90px;
  background-color: #3d6277;
}
.white-box ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.white-box ul li {
  padding-left: 20px;
  color: #18455d;
  line-height: 1.4;
  background-repeat: no-repeat;
  background-position: left 5px;
  background-size: 15px auto;
  margin-bottom: 25px;
}
.white-box ul li.incomplete {
  background-image: url(assets/img/icon-incomplete.svg);
}
.white-box ul li.complete {
  background-image: url(assets/img/icon-complete.svg);
}

.menu-sidebar {
  position: fixed;
  z-index: 101;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  transition: all linear 0.2s;
  -webkit-transform: translate(-110%, 0);
  -moz-transform: translate(-110%, 0);
  transform: translate(-110%, 0);
}
.menu-sidebar .menu-close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.menu-sidebar .box-menu {
  position: relative;
  z-index: 2;
  max-width: 310px;
  height: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 0 15px;
  overflow: auto;
}
.menu-sidebar .box-menu .profile-box {
  margin: 0 -15px;
  display: flex;
  background: #caedff;
  background: -moz-linear-gradient(left, #caedff 0%, #ffe7da 100%);
  background: -webkit-linear-gradient(left, #caedff 0%, #ffe7da 100%);
  background: linear-gradient(to right, #caedff 0%, #ffe7da 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#caedff', endColorstr='#ffe7da',GradientType=1 );
  padding: 45px 15px 20px;
}
.menu-sidebar .box-menu .profile-box .img {
  width: 70px;
}
.menu-sidebar .box-menu .profile-box .img img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #18455d;
}
.menu-sidebar .box-menu .profile-box .info {
  width: calc(100% - 70px);
  padding-left: 10px;
}
.menu-sidebar .box-menu .profile-box .info span {
  display: block;
  line-height: 1;
}
.menu-sidebar .box-menu .profile-box .info .welcome {
  font-size: 12px;
  font-weight: 700;
  color: #18455d;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.menu-sidebar .box-menu .profile-box .info .name {
  font-weight: 700;
  color: #ed7133;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.menu-sidebar .box-menu .profile-box .info .rewards {
  font-size: 12px;
}
.menu-sidebar .box-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-sidebar .box-menu ul li {
  border-bottom: 1px solid #c7c3c3;
}
.menu-sidebar .box-menu ul li a {
  display: block;
  position: relative;
  padding: 15px 15px 15px 25px;
}
.menu-sidebar .box-menu ul li a span {
  display: block;
  line-height: 1;
}
.menu-sidebar .box-menu ul li a [class*="icon-"] {
  position: absolute;
  top: 15px;
  left: 0;
  bottom: 0;
  width: 15px;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
}
.menu-sidebar .box-menu ul li a [class*="icon-"].icon-home {
  background-image: url(assets/img/icon-menu-home.svg);
}
.menu-sidebar .box-menu ul li a [class*="icon-"].icon-leaderboard {
  background-image: url(assets/img/icon-menu-leaderboard.svg);
}
.menu-sidebar .box-menu ul li a [class*="icon-"].icon-activity {
  background-image: url(assets/img/icon-menu-activity.svg);
}
.menu-sidebar .box-menu ul li a [class*="icon-"].icon-resources {
  background-image: url(assets/img/icon-menu-resources.svg);
}
.menu-sidebar .box-menu ul li a [class*="icon-"].icon-disruptors {
  background-image: url(assets/img/icon-menu-disruptors.svg);
}
.menu-sidebar .box-menu ul li a [class*="icon-"].icon-rules {
  background-image: url(assets/img/icon-menu-rules.svg);
}
.menu-sidebar .box-menu ul li a [class*="icon-"].icon-search {
  background-image: url(assets/img/icon-menu-search.svg);
}
.menu-sidebar .box-menu ul li a .main-link {
  text-transform: uppercase;
  color: #18455d;
  font-weight: 700;
  margin-bottom: 5px;
}
.menu-sidebar .box-menu ul li a .sub-text {
  color: #787878;
  font-size: 14px;
}
.menu-sidebar .box-menu .menu-footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 10px;
}
.menu-sidebar .box-menu .menu-footer a {
  font-size: 12px;
  color: #18455d;
  text-transform: uppercase;
}
.menu-sidebar.active {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
}
.popup-logout {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.35);
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
  visibility: hidden;
}
.popup-logout .popup-box {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: #fff;
  padding: 45px 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
}
.popup-logout .popup-box p {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  color: #18455d;
  margin-bottom: 70px;
}
.popup-logout .popup-box .action {
  display: flex;
  justify-content: space-between;
}
.popup-logout .popup-box .action .btn {
  width: calc(50% - 8px);
}
.popup-logout.active {
  opacity: 1;
  visibility: visible;
}

.popup-external-website {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.35);
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
  visibility: hidden;
}
.popup-external-website .popup-box {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: #fff;
  padding: 45px 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
}
.popup-external-website .popup-box p {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  color: #18455d;
  margin-bottom: 70px;
}
.popup-external-website .popup-box .action {
  display: flex;
  justify-content: space-between;
}
.popup-external-website .popup-box .action .btn {
  width: calc(50% - 8px);
}
.popup-external-website.active {
  opacity: 1;
  visibility: visible;
}

.popup-prev-game {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.35);
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
  visibility: hidden;
}
.popup-prev-game .popup-box {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: #fff;
  padding: 45px 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
}
.popup-prev-game .popup-box p {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  color: #18455d;
  margin-bottom: 70px;
}
.popup-prev-game .popup-box .action {
  display: flex;
  justify-content: space-between;
}
.popup-prev-game .popup-box .action .btn {
  width: calc(50% - 8px);
}
.popup-prev-game.active {
  opacity: 1;
  visibility: visible;
}

.popup-disruptor {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.35);
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
  visibility: hidden;
}
.popup-disruptor .popup-box {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: #fff;
  padding: 45px 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
}
.popup-disruptor .popup-box p {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  color: #18455d;
  margin-bottom: 70px;
}
.popup-disruptor .popup-box .action {
  display: flex;
  justify-content: space-between;
}
.popup-disruptor .popup-box .action .btn {
  width: calc(50% - 8px);
}
.popup-disruptor.active {
  opacity: 1;
  visibility: visible;
}

.page-game-rules {
  padding: 160px 15px 15px;
}
.page-game-rules .heading {
  margin-left: -15px;
  background: url(assets/img/bg-rules-heading.jpg) no-repeat left top;
  background-size: 100% auto;
  color: #fff;
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  height: 58px;
  padding: 13px 0 0 15px;
  margin-bottom: 10px;
}
.page-game-rules ul {
  margin: 0 0 50px;
  padding: 0;
  list-style: none;
}
.page-game-rules ul li {
  list-style: none;
  background: url(assets/img/icon-list-arrow.svg) no-repeat left 5px;
  background-size: 15px auto;
  padding-left: 25px;
  margin-bottom: 15px;
  position: relative;
}
.page-game-rules ul li ul {
  margin: 0;
}
.page-game-rules ul li ul li {
  background-image: none;
  padding-left: 15px;
  font-size: 14px;
  margin-bottom: 10px;
}
.page-game-rules ul li ul li::before {
  position: absolute;
  content: "";
  top: 10px;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: #ed7133;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.page-game-rules ul li:last-child {
  margin-bottom: 0;
}
.lmi-page {
  position: relative;
  z-index: 1;
  padding: 130px 15px 0px;
  width: 100%;
}
.lmi-page .tabs {
  display: flex;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}
.lmi-page .tabs a {
  display: block;
  width: 50%;
  background-color: #18455d;
  text-align: center;
  border-right: 1px solid #fff;
  color: rgba(255, 255, 255, 0.3) !important;
  font-weight: 600;
  line-height: 1;
  padding: 10px;
}
.lmi-page .tabs a:first-child {
  -webkit-border-radius: 10px 0 0 10px;
  -moz-border-radius: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;
}
.lmi-page .tabs a:last-child {
  -webkit-border-radius: 0 10px 10px 0;
  -moz-border-radius: 0 10px 10px 0;
  border-radius: 0 10px 10px 0;
  border-right: 0 none;
}
.lmi-page .tabs a.active {
  text-transform: uppercase;
  color: #fff !important;
}
.lmi-page .lmi-content-box {
  margin-bottom: 30px;
}
.lmi-page .lmi-content-box h4 {
  color: #18455d;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 10px;
}
.lmi-page .lmi-content-box p {
  font-size: 14px;
}
.lmi-page .lmi-content-box p a {
  font-weight: 700;
  color: #ed7133;
}
#map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(assets/img/googleMap.jpg) no-repeat left top;
  background-size: cover;
}
[class*="star-rating-"] {
  display: inline-block;
  background: url(assets/img/icon-star.svg) no-repeat left top;
  background-size: 100% auto;
  width: 60px;
  height: 15px;
}
[class*="star-rating-"].star-rating-0 {
  background-position: left top;
}
[class*="star-rating-"].star-rating-1 {
  background-position: left -16px;
}
[class*="star-rating-"].star-rating-2 {
  background-position: left -32px;
}
[class*="star-rating-"].star-rating-3 {
  background-position: left -48px;
}
.lmi-card {
  margin-bottom: 25px;
  background-color: #fafafa;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
.lmi-card .card-header {
  background-color: #18455d;
  color: #fff;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  padding: 5px 10px 8px;
  line-height: 1.2;
  font-weight: 600;
}
.lmi-card .card-body {
  padding: 10px;
}
.lmi-card .card-body .list {
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.lmi-card .card-body .list li {
  width: 33.33%;
  font-weight: 700;
  line-height: 1;
  display: flex;
  margin-bottom: 15px;
}
.lmi-card .card-body .list li .text {
  width: 25px;
}
.lmi-card .card-body .list li [class*="star-rating-"] {
  margin-left: 5px;
}
.lmi-card .card-body .btn {
  font-size: 20px;
  padding: 10px;
}
.lmi-card .card-body table {
  width: 100%;
  border-color: #18455d;
  text-align: center;
}
.lmi-card .card-body table th,
.lmi-card .card-body table td {
  border-color: #18455d;
  font-size: 12px;
}
.lmi-card .card-body table th {
  padding: 5px;
  background-color: transparent;
  font-weight: 600;
  text-transform: none;
  color: #18455d;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.lmi-card .card-body table th:first-child {
  text-align: left;
}
.lmi-card .card-body table td {
  border-top: 0 none;
  vertical-align: top;
  padding: 10px 5px;
}
.lmi-card .card-body table td [class*="star-rating-"] {
  margin-top: 5px;
}
.lmi-card .card-body table td:first-child {
  text-align: left;
}
.degrees {
  margin: 0;
  padding: 0 0 15px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.degrees li {
  width: 46%;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 15px;
}
.degrees li [class*="star-rating-"] {
  margin-right: 5px;
}
.degrees li:nth-child(even) {
  width: 54%;
}
.activity-content {
  padding-top: 70px;
}
.activity-content img {
  display: block;
  width: 100%;
}
.activity-content .content {
  padding: 25px 15px;
}
.activity-content .content h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.activity-content .content ol {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: count-list;
}
.activity-content .content ol li {
  position: relative;
  padding-left: 15px;
  font-size: 14px;
  margin-bottom: 5px;
}
.activity-content .content ol li::before {
  position: absolute;
  counter-increment: count-list;
  content: counter(count-list) ".";
  top: 1;
  left: 0;
}
.activity-3 {
  padding: 150px 15px 15px;
}
.activity-3 h3 {
  margin-left: -15px;
  background: url(assets/img/bg-heading-activity.png) no-repeat left top;
  background-size: 100% auto;
  color: #fff;
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  height: 58px;
  padding: 15px 0 0 15px;
  margin-bottom: 10px;
}
.activity-3 p {
  font-size: 14px;
  margin-bottom: 30px;
}
.activity-3 ul {
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
}
.activity-3 ul li {
  list-style: none;
  background: url(assets/img/icon-list-arrow.svg) no-repeat left 5px;
  background-size: 15px auto;
  padding-left: 25px;
  margin-bottom: 5px;
  position: relative;
  font-size: 14px;
}
.activity-3 ul li:last-child {
  margin-bottom: 0;
}
.activity-3 .actions {
  display: flex;
  justify-content: space-between;
  background-color: rgba(216, 216, 216, 0.3);
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
.activity-3 .actions a {
  line-height: 1;
}
.activity-3 .actions .download {
  background: url(assets/img/icon-download.svg) no-repeat 20px center;
  background-size: 20px auto;
  padding: 15px 15px 15px 50px;
  font-weight: 700;
  color: #ed7133;
}
.activity-3 .actions .share {
  font-size: 12px;
  padding: 6px 20px 0;
}
.activity-3 .actions .share span {
  display: block;
  width: 20px;
  height: 17px;
  margin: 0 auto 5px;
  background: url(assets/img/icon-share.svg) no-repeat center top;
  background-size: 100% auto;
}

.disruptor-page {
  padding: 150px 15px 15px;
}
.disruptor-page h3 {
  margin-left: -15px;
  background: url(assets/img/bg-heading-activity.png) no-repeat left top;
  background-size: 100% auto;
  color: #fff;
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  height: 58px;
  padding: 15px 35px 0 15px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}
.disruptor-page ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.disruptor-page ul li {
  list-style: none;
  background: url(assets/img/icon-list-arrow.svg) no-repeat left 5px;
  background-size: 15px auto;
  padding-left: 25px;
  margin-bottom: 15px;
  position: relative;
  font-size: 14px;
}
.disruptor-page ul li:last-child {
  margin-bottom: 0;
}
.continue-game-page {
  padding: 200px 15px 15px;
}
.continue-game-page .game-box {
  position: relative;
  background: url(assets/img/bg-continue-game.svg) no-repeat center top;
  background-size: 100% auto;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 0;
  margin-bottom: 90px;
}
.continue-game-page .game-box .icon-circle-warning {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background: url(assets/img/icon-circle-warning.svg) no-repeat center center
    #fff;
  background-size: 100% auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.continue-game-page .game-box p {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
  text-align: center;
}
.continue-game-page .game-box .game-dropdown {
  position: absolute;
  top: 100%;
  left: 15px;
  right: 15px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.continue-game-page
  .game-box
  .game-dropdown
  .chosen-container-single
  .chosen-single {
  background: url(assets/img/bg-dropdown-gradient.svg) no-repeat center top #fff;
  background-size: 100% auto;
  height: 46px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.my-swal-button-class {
  font-size: 12px !important;
}

@media all and (max-width: 359px) {
  .header.large {
    min-height: 40vh;
  }
  .field.mb-75 {
    margin-bottom: 60px;
  }
  .header.medium {
    min-height: 145px;
  }
  .header.large .logo {
    height: 30vh;
  }
  .header.small .logo {
    height: 45px;
  }
  .header.small .logo img {
    width: 65%;
  }
  .header.medium .logo {
    height: 70px;
  }
  .header.small {
    min-height: 90px;
  }
  .header.menu-header {
    padding: 0 0 45px;
  }
  .header .page-heading {
    font-size: 15px;
  }
  .bg-gradient .header {
    padding: 0;
  }
  .form-section {
    padding-top: 45vh;
  }
  .form-section.signup {
    padding-top: 150px;
  }
  .form-section.edit-profile {
    padding-top: 120px;
  }
  .page-block,
  .activity-success-block,
  .activity-3,
  .congratulations-page,
  .page-game-rules,
  .resources-page,
  .form-section.lmi,
  .lmi-page,
  .notification-page,
  .announcement-page,
  .faq-page,
  .disruptor-page {
    padding-top: 120px;
  }
  .game-home,
  .activity-content {
    padding-top: 50px;
  }
  .confirmation-block {
    padding-top: 160px;
  }
  .confirmation-block .confirmation-box {
    height: 205px;
    margin-bottom: 60px;
  }
  .activity-3 h3 {
    font-size: 16px;
    height: 50px;
  }
  .congratulations-page p.info {
    margin-top: 50px;
  }
  .menu-sidebar .box-menu {
    max-width: 280px;
  }
  .page-game-rules .heading {
    font-size: 16px;
    height: 50px;
  }
  .leaderboard-page {
    padding-top: 330px;
  }
  .leaderboard-page .profile-block {
    top: 70px;
  }
  .resources-page .block-resource a {
    padding-top: 50px;
    height: 100px;
  }
  .resources-page .block-resource a .icon-circle {
    top: -20px;
    width: 50px;
    height: 50px;
  }
  .resources-page .block-resource a .text {
    font-size: 22px;
  }
  [class*="star-rating-"] {
    width: 50px;
    height: 14px;
  }
  [class*="star-rating-"].star-rating-1 {
    background-position: left -13px;
  }
  [class*="star-rating-"].star-rating-2 {
    background-position: left -26px;
  }
  [class*="star-rating-"].star-rating-3 {
    background-position: left -40px;
  }
  .degrees li:nth-child(even) {
    padding-left: 10px;
  }
  .lmi-card .card-header {
    font-size: 15px;
  }
  .job-search-page {
    padding: 120px 15px 15px;
  }
  .notification-page .bottom-block .btn-clear {
    margin-bottom: 10px;
  }
}

/* scroll down animation */

.scroll-down-btn {
  width: 30px;
  height: 30px;
  position: fixed;
  bottom: 5px;
  right: -10px;
  z-index: 1000;
}

.scroll-down-btn span {
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.scroll-down-btn span:nth-child(2) {
  animation-delay: -0.2s;
}

.scroll-down-btn span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-15px, -15px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(15px, 15px);
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
